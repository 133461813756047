import Space from '@/components/atoms/Space'
import SelectorGroup from '@/components/molecules/SelectorGroup'
import AnalysisYearSelector from '@/components/molecules/SelectorGroup/analysisYearSelect'
import SiteSelect from '@/components/organisms/SiteSelect'
import AllScopes from '@/components/template/Analysis/Overview/AllScopes'
import Scope3 from '@/components/template/Analysis/Overview/Scope3'
import Scopes1and2 from '@/components/template/Analysis/Overview/Scopes1and2'
import DateSelector from '@/components/template/Analysis/Selector/DateSelector'
import { withLayout } from '@/components/template/Layout'
import SimpleTabs, { SimpleTabProps } from '@/components/template/SimpleTabs'
import { makeMonth, organizationApi, siteApi, siteSummaryApi } from '@/ghgApi'
import useAnalysis from '@/hooks/useAnalysis'
import {
    AnnualSiteSummary,
    HalfYearSiteSummary,
    MonthlySiteSummary,
    Objective,
    QuarterlySiteSummary,
    YearSummaryItem,
} from '@/openapi/api'
import theme from '@/theme'
import { getRootSiteId } from '@/utils/siteNodeHelper'
import useStore from '@/zustand/sotre'
import * as FileSaver from 'file-saver'
import React, { useEffect, useMemo, useRef, useState, CSSProperties } from 'react'
import { useReactToPrint } from 'react-to-print'
import { TAllData } from './organization'
import { MenuItemProps } from '@/components/atoms/SelectField'
export type CompareResponseSiteData = AnnualSiteSummary | HalfYearSiteSummary | QuarterlySiteSummary

export type ResponseSiteData = CompareResponseSiteData | MonthlySiteSummary
// ====================================================================================
// Overview
// ====================================================================================

const styles: { [key: string]: CSSProperties } = {
    loading: {
        marginTop: 30,
    },
}

const OverviewInner = () => {
    const {
        storeState,
        disabledScope3,
        analysisState,
        tabValue,
        expandedMenu,
        setAnalysisState,
        setTabValue,
        setSelectedSite,
        setStoreState,
    } = useStore()
    const [allData, setAllData] = useState<TAllData>()
    const [responseData, setResponseData] = useState<ResponseSiteData>()
    const [objective, setObjective] = useState<Objective>()
    const [csvData, setCsvData] = useState<Blob>()
    const [yearSelectorHeight, setYearSelectorHeight] = useState<number>(0)
    const [isPrinting, setIsPrinting] = useState(false)
    const { setInitialAnalysisState } = useAnalysis()
    const [resDataChartCompare, setResDataChartCompare] = useState<Array<CompareResponseSiteData>>([])
    const [siteOptions, setSiteOptions] = useState<MenuItemProps[]>([])
    const componentRef = useRef<HTMLDivElement>(null)
    const promiseResolveRef = useRef<any>(null)

    const organizationId = storeState.organization?.id
    const siteId = useMemo(
        () => analysisState.selectedSite?.id || storeState.selectedSite?.id,
        [siteOptions, analysisState.selectedSite, storeState.selectedSite],
    )
    const dateString = analysisState.date.month
        ? `${analysisState.date.year}年${analysisState.date.month}月`
        : `${analysisState.date.year}年度`
    useEffect(() => {
        if (!siteId) {
            // TODO: tobe improved
            setSelectedSite(storeState.sites[0])
            setAnalysisState({ ...analysisState, selectedSite: storeState.sites[0] })
            return
        }
        if (analysisState.date.month) {
            const year =
                (storeState.organization?.startMonth as number) === 1 ||
                analysisState.date.month >= analysisState.dateSelector.options.months[0]
                    ? analysisState.date.year
                    : analysisState.date.year + 1
            siteSummaryApi
                .getMonthlySiteSummary(siteId, makeMonth(year, analysisState.date.month))
                .then((res) => setResponseData(res.data))
                .catch((err) => {
                    if (err.response?.status != 404) console.warn('SiteApi.getOrganizationSummaries:', err)
                })
        }
        if (analysisState.date.half) {
            const resDataEnd = siteSummaryApi
                .getHalfYearSiteSummary(siteId, analysisState.date.year, analysisState.date.half)
                .then((res) => res.data)
            const resDataStart = siteSummaryApi
                .getHalfYearSiteSummary(siteId, analysisState.date.compareYear, analysisState.date.half)
                .then((res) => res.data)

            Promise.all([resDataEnd, resDataStart]).then((data) => {
                setResponseData(data[0])
                setResDataChartCompare(data)
            })
        }
        if (analysisState.date.quarter) {
            const resDataEnd = siteSummaryApi
                .getQuarterlySiteSummary(siteId, analysisState.date.year, analysisState.date.quarter)
                .then((res) => res.data)
            const resDataStart = siteSummaryApi
                .getQuarterlySiteSummary(siteId, analysisState.date.compareYear, analysisState.date.quarter)
                .then((res) => res.data)

            Promise.all([resDataEnd, resDataStart]).then((data) => {
                setResponseData(data[0])
                setResDataChartCompare(data)
            })
        }
        if (
            analysisState.date.month === null &&
            analysisState.date.half === null &&
            analysisState.date.quarter === null &&
            analysisState.date.year
        ) {
            const resDataEnd = siteSummaryApi
                .getAnnualSiteSummary(siteId, analysisState.date.year)
                .then((res) => res.data)
            const resDataStart = siteSummaryApi
                .getAnnualSiteSummary(siteId, analysisState.date.compareYear)
                .then((res) => res.data)
            Promise.all([resDataEnd, resDataStart]).then((data) => {
                setResponseData(data[0])
                setResDataChartCompare(data)
            })
        }
        setAnalysisState({
            ...analysisState,
            detailCategoryId: undefined,
            detailCategoryTitle: undefined,
        })
    }, [
        analysisState.date.month,
        analysisState.date.year,
        analysisState.date.half,
        analysisState.date.quarter,
        analysisState.date.compareYear,
        siteId,
    ])

    useEffect(() => {
        if (siteId && organizationId) {
            organizationApi
                .getOrganizationSitesObjectives()
                .then((res) => {
                    const rootSiteId = getRootSiteId(siteId, storeState.sites)
                    const siteObjectiveFromApi = res.data.sites.find((siteObjective) => siteObjective.siteId == rootSiteId)
                    const objectiveFromApi = siteObjectiveFromApi?.objectives.find(
                        (objective) => objective.year === analysisState.date.year,
                    ) || { year: analysisState.date.year, text: '' }
                    setObjective(objectiveFromApi)
                })
                .catch((err) => {
                    if (err.response?.status != 404)
                        console.warn('OrganizationApi.getOrganizationSitesObjectives:', err)
                })
        }
    }, [siteId, analysisState.selectedSite, analysisState.date.year])

    useEffect(() => {
        if (!siteId) return
        const getAllData = async () => {
            const res = await Promise.all([
                siteSummaryApi.getYearSiteSummary(siteId),
                siteSummaryApi.getMonthSiteSummary(siteId),
            ])
            const data: YearSummaryItem[] = []
            analysisState.dateSelector.options.years.forEach((year) => {
                const findYear = res[0].data.years.find((d) => d.year === year)
                if (findYear) data.unshift(findYear)
                else data.unshift({ year, quantity: 0, scopes: [] })
            })
            setAllData({ dataByYear: data, dataByMonth: res[1].data })
        }
        if (analysisState.dateSelector.options.years.length) getAllData()
    }, [analysisState.dateSelector.options.years, siteId])

    useEffect(() => {
        if (storeState.organization?.startMonth) {
            setInitialAnalysisState(storeState.organization.startMonth)
            setTabValue(0)
        }
        fetchOrganizationSites()
    },[])

    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
            promiseResolveRef.current()
        }
    }, [isPrinting])

    const fetchOrganizationSites = async () => {
        await siteApi.listSite(undefined, undefined, 1000)
            .then((res) => {
                if (!res.data.data) {
                    console.log('siteApi.listSite response empty-sites.')
                    return
                }
                const sites = res.data.data
                const options = sites.map((s) => ({ id: s.id, value: s.name })) || []
                setSiteOptions(options)
                setStoreState({
                    ...storeState,
                    sites: sites,
                })
                setAnalysisState({
                    ...analysisState,
                    sites: sites,
                })
            })
            .catch((err) => console.log(err))
    }

    const handlePrint = useReactToPrint({
        pageStyle: `@media print {
                 @page {
                    size: 450mm 600mm;
                   margin: 20;
                 }
               }`,
        content: () => componentRef.current,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve
                setIsPrinting(true)
            })
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null
            setIsPrinting(false)
        },
    })
    const tabs: SimpleTabProps[] = [
        {
            label: '全Scope',
            content:
                responseData && resDataChartCompare.length && allData ? (
                    <AllScopes
                        date={dateString}
                        apiData={responseData}
                        resDataChartCompare={resDataChartCompare}
                        allData={allData}
                        ref={componentRef}
                        setCsvData={setCsvData}
                        siteName={storeState.selectedSite?.name}
                        objective={objective}
                        isPrinting={isPrinting}
                    />
                ) : (
                    <div style={styles.loading}>loading...</div>
                ),
        },
        {
            label: 'Scope1',
            content:
                responseData && resDataChartCompare.length && allData ? (
                    <Scopes1and2
                        date={dateString}
                        apiData={responseData}
                        resDataChartCompare={resDataChartCompare}
                        scope={1}
                        allData={allData}
                        ref={componentRef}
                        setCsvData={setCsvData}
                        siteName={storeState.selectedSite?.name}
                        objective={objective}
                        isPrinting={isPrinting}
                    />
                ) : (
                    <div style={styles.loading}>loading...</div>
                ),
        },
        {
            label: 'Scope2',
            content:
                responseData && resDataChartCompare.length && allData ? (
                    <Scopes1and2
                        date={dateString}
                        apiData={responseData}
                        resDataChartCompare={resDataChartCompare}
                        scope={2}
                        allData={allData}
                        ref={componentRef}
                        setCsvData={setCsvData}
                        siteName={storeState.selectedSite?.name}
                        objective={objective}
                        isPrinting={isPrinting}
                    />
                ) : (
                    <div style={styles.loading}>loading...</div>
                ),
        },
        {
            label: 'Scope3',
            content:
                responseData && resDataChartCompare.length && allData ? (
                    <Scope3
                        date={dateString}
                        apiData={responseData}
                        resDataChartCompare={resDataChartCompare}
                        allData={allData}
                        ref={componentRef}
                        setCsvData={setCsvData}
                        siteName={storeState.selectedSite?.name}
                        objective={objective}
                        isPrinting={isPrinting}
                    />
                ) : (
                    <div style={styles.loading}>loading...</div>
                ),
            disabled: disabledScope3,
        },
    ]

    const handleCsvCick = () => {
        if (csvData) FileSaver.saveAs(csvData, `analysis-data-table-scope.csv`)
        else console.warn('no data to download')
    }
    const handleSiteChange = (selectedSiteId: number | null) => {
        if (!selectedSiteId) {
            return
        }
        const selectedSite = storeState.sites.find(s => s.id === selectedSiteId as number)
        if (!selectedSite) {
            return
        }
        setSelectedSite(selectedSite)
        setAnalysisState({
            ...analysisState,
            selectedSite: selectedSite
        })
    }
    const stylesFixed = {
        width: `calc(100% - ${expandedMenu ? 300 : 120}px)`,
        transition: 'width 0.225s cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    }

    return (
        <main>
            <title>データ分析（拠点別）｜Emission View</title>
            <div
                style={{
                    position: 'fixed',
                    zIndex: 2,
                    top: 70,
                    ...stylesFixed,
                }}
            >
                <Space />
                <SelectorGroup options={siteOptions} isAnalysis />
                <SiteSelect 
                    sites={storeState.sites}
                    onChange={handleSiteChange}
                    isAnalysis={true}
                />
                <AnalysisYearSelector
                    objective={objective}
                    onCsvClick={handleCsvCick}
                    onPdfClick={handlePrint}
                    setYearSelectorHeight={setYearSelectorHeight}
                />
                <DateSelector />
            </div>
            <div style={{ marginTop: 20 }}>
                <SimpleTabs
                    tabs={tabs}
                    value={tabValue}
                    tabFixed={{
                        position: 'fixed',
                        top: yearSelectorHeight + 280,
                        zIndex: 2,
                        backgroundColor: theme.colors.white,
                        ...stylesFixed,
                    }}
                    contentSpaceTop={yearSelectorHeight + 200}
                />
            </div>
        </main>
    )
}

const Overview = () => <OverviewInner />

export default withLayout(Overview)
